<template>
<div class="c-about__title" :style="{padding: data.padding}">
  {{data.text && data.text}}
</div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {

    }
  },
  props: {
    data: {
      type: Object,
      default: () =>{
        return {}
      }
    }
  },
  created(){

  },
  mounted() {

  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-about__title {
  font-size: $fs-title-m;
  font-weight: bold;
  color: $color2;
}
</style>