<template>
<div class="p-about">
  <c-swiper :banner="banner"></c-swiper>
  <!-- 公司简介 -->
  <div class="p-about__sketch container">
    <c-about-title :data="titleArr[0]"></c-about-title>
    <div class="p-about__sketch__bd">
      <div class="p-about__sketch__left hidden-xs">
        <img :src="sketch.src" alt="">
      </div>
      <div class="p-about__sketch__right" :style="{'padding': isPadding ?  '0px' : `0px ${mPadding}px`}">
        <div class="p-about__sketch__wrapper">
          <div class="p-about__sketch__title">{{sketch.title}}</div>
          <div class="p-about__sketch__text">
            <div class="p-about__sketch__text__item" v-for="(item, index) in sketch.text">
              {{item}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 公司环境 -->
  <div class="p-about__environment container">
    <c-about-title :data="titleArr[1]"></c-about-title>
    <div class="p-about__environment__bd" :style="{'justify-content': isPadding ?  'space-between' : 'center'}">
      <div class="p-about__environment__bd__item" 
      v-for="(item, index) in env" 
      :key="index + item.src">
        <img :src="item.src" alt="">
      </div>
    </div>
  </div>
  <!-- 联系我们 -->
  <div class="p-about__us container">
    <c-about-title :data="titleArr[2]"></c-about-title>
    <div class="p-about__us__bd">
      <div class="p-about__us__map">
        <iframe id="deadSdl" width="100%" height="100%" src="/map.html" frameborder="0" scrolling="no"></iframe>
      </div>
    </div>
  </div>
  <!-- 深圳总部 -->
  <div class="p-about__headquarters container-fluid">
    <c-about-title :data="titleArr[3]"></c-about-title>
    <div class="p-about__headquarters__bd container clearfix">
      <div class="p-about__headquarters__bd__wrapper">
        <div class="row">
          <c-about-headquarters-box
          class="p-about__headquarters__bd__item col-xs-12 col-sm-6 col-md-3 col-lg-3"
          v-for="(item, index) in headquarters"
          :key="index + item.title"
          :data="item"
          ></c-about-headquarters-box>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
import base from '../../components/base/base.vue'
import cAboutTitle from './components/c-about-title.vue'
import cAboutHeadquartersBox from './components/c-about-headquarters-box.vue'
import banner from '../../assets/images/about/banner.png'
import sz from '../../assets/images/about/sz.png'
import company0 from '../../assets/images/about/company_0.png'
import company1 from '../../assets/images/about/company_1.png'
import company2 from '../../assets/images/about/company_2.png'
import company3 from '../../assets/images/about/company_3.png'
import callus0 from '../../assets/images/about/callus_0.png'
import callus1 from '../../assets/images/about/callus_1.png'
import callus2 from '../../assets/images/about/callus_2.png'
import callus3 from '../../assets/images/about/callus_3.png'
import callusQrcode from '../../assets/images/about/callus_qrcode.png'
export default {
  extends: base,
  data() {
    return {
      banner: [
        {src: banner, isNoClick: true}
      ],
      titleArr: [
        {text: '公司简介', padding: '65px 0px 75px'},
        {text: '公司环境', padding: '65px 0px 75px'},
        {text: '联系我们', padding: '65px 0px 75px'},
        {text: '深圳总部', padding: '25px 0px 45px'}
      ],
      sketch: {
        src: sz,
        title: '专注研发更贴合市场发展的产品',
        text: [
          '深圳市龙火科技有限公司(ShenZhen LOOFIRE Technology Co. Ltd.) ，以专业的开发技术及严谨态度，获批通过国家高新技术企业、深圳市高新技术企业评定。',
          '公司现有“即时聊天、信用卡积分兑换、积分POS一体机、AI智控引 流云控系统、手游、线上商城、钱包系统等”自主产品。通过以用户为核心，为实现用户目标而提供更便捷，更人性化的产品而获得了众多客户与用户的肯定。'
        ]
      },
      env: [
        {src: company0},
        {src: company1},
        {src: company2},
        {src: company3}
      ],
      headquarters: [
        {
          src: callus0,
          title: '公司地址',
          text: {
            type: 1,
            src: '',
            text: [
              '深圳市龙华区淘金地大厦',
              'E座607室',
              '百度导航：深圳龙火科技'
            ]
          }
        },
        {
          src: callus1,
          title: '联系方式',
          text: {
            type: 1,
            src: '',
            text: [
              'QQ：2133359336  ',
              '服务电话：0755-23775803',
              '客服热线：400-995-0605'
            ]
          }
        },
        {
          src: callus2,
          title: '龙火邮箱',
          text: {
            type: 1,
            src: '',
            text: [
              '销售：1078561110@qq.com',
              '服务：1078561110@qq.com',
              '官方网址：www.loofire.com'
            ]
          }
        },
        {
          src: callus3,
          title: '微信公众号',
          text: {
            type: 0,
            src: callusQrcode,
            text: []
          }
        }
      ]
    }
  },
  created(){

  },
  mounted() {
  },
  components: {
    cAboutTitle,
    cAboutHeadquartersBox
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.p-about {
  &__sketch {
    &__bd {
      max-width: 1140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
    &__right {
      max-width: 615px;
    }
    &__title {
      font-size: $fs-title-xs;
      color: $color3;
      margin-bottom: 30px;
    }
    &__text {
      font-size: $fs-text-l;
      color: $color4;
      &__item {
        margin-bottom: 30px;
      }
    }
  }
  &__environment {
    &__bd {
      max-width: 1140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &__item {
        margin-bottom: 10px;
      }
    }
  }
  &__us {
    &__map {
      height: 384px;
      box-sizing: content-box;
      padding-bottom: 60px;
    }
  }
  &__headquarters {
    background-color: #EDF0F5;
    padding-bottom: 20px;
    &__bd {
      overflow: hidden;
      &__wrapper {
        position: relative;
        left: 1px ;
        top: 1px;
      }
    }
  }
}
</style>