<template>
<div class="c-about-headquarters-box" :style="{'align-items': 'flex-start'}">
  <div class="c-about-headquarters-box__wrapper">
    <div class="c-about-headquarters-box__icon">
      <img :src="data.src" alt="">
    </div>
    <div class="c-about-headquarters-box__title">{{data.title}}</div>
    <div class="c-about-headquarters-box__text" v-if="data.text.type">
      <div 
      class="c-about-headquarters-box__text__item" 
      v-for="(item, index) in data.text.text" 
      :key="item + index">{{item}}</div>
    </div>
    <div class="c-about-headquarters-box__img" v-else>
      <img :src="data.text.src" alt="">
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {

    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created(){

  },
  mounted() {

  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-about-headquarters-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #00FCFE;
  border-bottom: 1px solid #00FCFE;
  min-height: 320px;
  &__icon {
    margin-bottom: 30px;
  }
  &__title {
    font-size: $fs-title-xs;
    color: $color3;
    margin-bottom: 30px;
  }
  &__text {
    font-size: $fs-text-m;
    color: $color4;
    &__item {
      margin-bottom: 20px;
    }
  }
}
</style>